import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Header from './Header';
import backgroundImage from '../../static/images/bg3.jpg';
import Footer from './Footer';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <div
        className="bg-no-repeat bg-center bg-cover bg-fixed min-h-screen flex flex-col"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="container mx-auto flex-grow text-white text-xl px-8 tracking-normal font-sans leading-normal flex">
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
