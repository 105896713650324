/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';

const Footer = (): JSX.Element => {
  return (
    <footer
      className="grid grid-cols-2 md:grid-cols-5 gap-12 justify-center p-6 font-sans"
      style={{ backgroundColor: '#171717' }}
    >
      <div className="flex flex-col md:col-start-2 md:col-end-2">
        <h6 className="text-teal font-bold">Epost</h6>
        <a
          className="text-blue-600 hover:text-blue-500"
          href="mailto:hello@mittskolval.se"
        >
          hello@mittskolval.se
        </a>
        <br />
      </div>
      <div className="flex flex-col md:col-start-4 md:col-end-4">
        <h6 className="text-teal font-bold">Postadress</h6>
        <ul className="text-white">
          <li>Mitt Skolval</li>
          <li>Dalagatan 7</li>
          <li>11123, Stockholm</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
