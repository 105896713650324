import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';
import Img from 'gatsby-image';
import { FaBars } from 'react-icons/fa';
import { useWindowWidth } from '@react-hook/window-size/throttled';

type MenuItem = { path: string; text: string };

const items: MenuItem[] = [
  { path: '/municipality', text: 'För kommuner' },
  { path: '/school', text: 'För fristående skolor' },
  { path: '/jobs', text: 'Jobba hos oss' },
  { path: '/press', text: 'Press' },
  { path: '/about', text: 'Om oss' },
];

type HeaderProps = {
  siteTitle: string;
};

const Header = ({ siteTitle }: HeaderProps) => {
  const MOBILE_SIZE = 1024;
  const windowWidth = useWindowWidth();
  const [open, setOpen] = useState(false);

  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logga.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const showBurger = windowWidth < MOBILE_SIZE;
  const showItems = !showBurger || (showBurger && open);

  return (
    <header className="bg-white shadow">
      <div className="lg:container flex flex-col lg:flex-row lg:justify-between mx-auto">
        <div className="flex flex-row justify-between">
          <Link to="/" className="m-2 ml-8 flex">
            <Img
              className="m-auto"
              style={{ width: 100 }}
              fluid={logo.file.childImageSharp.fluid}
            />
          </Link>

          {showBurger && (
            <button
              onClick={toggleMenu}
              className="pl-4 pr-4 mr-8 bg-white active:bg-gray-100 focus:bg-gray-200 text-gray-800 active:text-gray-600 focus:outline-none"
            >
              <FaBars size={28} />
            </button>
          )}
        </div>

        {showItems && (
          <ul>
            {items.map((item) => (
              <li
                key={item.text}
                className="lg:float-left h-full lg:border-l border-b lg:border-b-0 border-gray-200 hover:bg-gray-200"
              >
                <Link to={item.path} className="h-full p-4 flex content-center">
                  <span className="m-auto">{item.text}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </header>
  );
};

export default Header;
